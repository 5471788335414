import ProductUnit from './ProductUnit.vue'
import ProductUnitCreate from './Create.vue'
import ProductUnitEdit from './Create.vue'
import ProductUnitShow from './Show.vue'

export default [
    {
        path: '/product_unit',
        name: 'ProductUnit',
        component: ProductUnit
    },
    {
        path: '/product_unit/create',
        name: 'ProductUnitCreate',
        component: ProductUnitCreate
    },
    {
        path: '/product_unit/edit/:id',
        name: 'ProductUnitEdit',
        component: ProductUnitEdit
    },
    {
        path: '/product_unit/show/:id',
        name: 'ProductUnitShow',
        component: ProductUnitShow
    },
]
