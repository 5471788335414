<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <div class="row">
        <div class="col-4">
          <div class="card">
            <div class="card-header">
              <h4>Filter</h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Select Product</label>
                <model-list-select
                  :list="products"
                  v-model="filter.product_id"
                  option-value="id"
                  option-text="name"
                  placeholder="Select Product"
                >
                </model-list-select>
              </div>
            </div>
            <div class="card-footer text-right">
              <button
                class="btn btn-primary mr-1"
                :disabled="isSubmitting"
                @click="getData"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductUnitCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="productUnits"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.product_name }}</td>
                    <td>{{ row.item.unit_name }}</td>
                    <td>
                      <span
                        v-if="row.item.is_sale == true"
                        class="fas fa-check"
                      >
                      </span>
                      <span v-else>
                        X
                      </span>
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductUnitEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteProductUnit(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <!-- <table class="table" v-else>
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(productUnit, index) in productUnits" :key="index">
                    <td>{{ productUnit.product_name }}</td>
                    <td>{{ productUnit.unit_name }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductUnitEdit',
                          params: { id: productUnit.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteProductUnit(productUnit.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import swal from "sweetalert";

export default {
  name: "ProductUnit",
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      filter: {
        product_id: "",
      },
      name: "Product Unit",
      productUnits: [],
      loading: false,
      products: [],
      isSubmitting: false,
    };
  },
  mounted() {
    this.getData();
    this.getProduct();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "product_unit", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.filter.product_id,
          },
        })
        .then((result) => {
          this.loading = false;
          this.productUnits = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProduct() {
      apiGet("product/all/minimal")
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteProductUnit(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "product_unit/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(() => this.getData())
            .catch((err) => {
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your Product Unit file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your Product Unit file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Product",
          value: "product_id",
        },
        {
          text: "Unit",
          value: "unit_id",
        },
        {
          text: "Sale",
          value: "is_sale",
        },
        { text: "Action" },
      ];
    },
  },
};
</script>
